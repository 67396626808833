<template>
  <el-col :span="24"><div ref="chart" class="CoreChart-num1" /></el-col>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    canvasData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
    }
  },
  watch: {
    'canvasData.id': {
      handler(val) {
        this.initOptions()
      },
      immediate: true // 立即执行
    }
  },
  mounted() {
  },
  methods: {
    initOptions() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(this.$refs.chart)
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['盒子上架数', '售出数']
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.canvasData.date || []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '盒子上架数',
            type: 'line',
            data: this.canvasData.up_sell_number.up_number || []
          },
          {
            name: '售出数',
            type: 'line',
            data: this.canvasData.up_sell_number.sell_number || []
          }
        ]
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .CoreChart-num1{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
